import { Link } from "react-router-dom"
import {Container,Nav,NavDropdown,Navbar} from 'react-bootstrap'
function NavBar(){
    return (
        <div className="row">
            <div className="col-md-12">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <Link  className="navbar-brand" to="/">Home</Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                <li className="nav-item active">
                <Link  className="navbar-brand" to="about">About</Link>
                </li>
                <li className="nav-item">
                <Link  className="navbar-brand" to="/product">Product</Link>
                </li>
                <li className="nav-item">
                <Link  className="navbar-brand" to="/contact">Contact</Link>
                </li>
                
                </ul>
                
                </div>
             </nav>
            </div>



           


        </div>
    )
}

export default NavBar