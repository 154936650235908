import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import Template from './pages/Template';
import Contact from './pages/Contact';
import About from './pages/About';
import Product from './pages/Product';
import Error from './pages/Error';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


function App() {
  return (
    <BrowserRouter>
    <Routes>
    <Route path="/" element={<Template />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="product" element={<Product />} />          
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<Error />} />
    </Route>
       

    </Routes>
    </BrowserRouter>
  );
}


export default App;
