import '../App.css'
const Footer =()=>{
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="App-header">
                    <p>This project is made by Vipin Gourkhede</p>
                </div>
            </div>
        </div>

    )
}
export default Footer