import Slider from "./Slider"

const Home = ()=>{
    return (
        <>
        <Slider></Slider>
        <h1> Home </h1>
        </>
    
    )
}
export default Home