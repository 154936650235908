import { Outlet } from "react-router-dom";
import NavBar from "./Navbar";
import Header from "./Header";
import Footer from "./Footer";

const Template = () => {
  return (
    <>
    <Header/>
     <NavBar/>
     

      <Outlet />
      <Footer/>
    </>
  )
};

export default Template;